.App {
  text-align: center;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  //background: black;
}

// wallet input changes

.walletInput {
  display: none;
}

.passwordInput {
  display: none;
}

.walletInput.active {
  display: block;
}

.passwordInput.active {
  display: block;
}

#passbtn {
  display: none;
}

#walletbtn {
  display: none;
}

#passbtn.active {
  display: block;
}

#walletbtn.active {
  display: block;
}

// Pan Container module 

.panContainer {
  height: 100vh;
  width: 100%;
  position: relative;
}

.fadeTemp {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  background: black;
  animation: fade;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.fadeTemp.fadeZin {
  z-index: -100;
}


@keyframes fade {

  from {
    background: black;
  }

  to {
    background: none;
  }
  
}



#mainBG {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: -0%;
  object-fit: cover;
  z-index: 0;
  transition: 0.5s;
  border-bottom: 2px solid white;
  display: none; //with preloader
}

#mainBG.active {
  top: -100.25%;
}

// landing module

.landing {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  transition: .5s;
  z-index: 1;
  display: none; // with preloader
}

#landing {
  top: 0%;
}

#landing.active {
  top: -100%;
}

.titleCon {
  padding: 0;
  height: 100%;
  width: 95%;
  margin-left: 3.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 999;
  //padding-top: 10%;
}

.titleCon h1 {
  color: white;
  font-size: 210px;
  padding: 0;
  margin: 0;
  margin-top: 200px;
  line-height: 1;
}

.titleCon button {
  border: none;
  padding: 4px 12px;
  padding-top: 5px;
  font-size: 20px;
  margin: 0;
  border-radius: 25px;
  background-color: white;
  color: #151515;
  margin-bottom: 50px;
  font-weight: 600;
  cursor: pointer;
}

.titleCon p {
  color: white;
  margin-top: 10px;
  font-size: 20px;

}

// divider 

/*.divider {
  height: 100%;
  width: .5%;
  background: white;
  position: absolute;
  top: 0;
  transition: .5s;
}

#divider {
  left: 100.5%;
}

#divider.active {
  left: -.5%;
}*/

// GenCon module

.genContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: 0.5s;
}

.genIMG {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -2;
}

.artCover {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0;
  background: black;
  transition: 2s;
}

.artCover.active {
  opacity: 1;
}

#genContainer {
  top: 0%;
}

#genContainer.active {
  top: 0%
}

.genNavBar {
  height: 13vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 998;
}

.navLogo {
  position: relative;
  display: grid;
  width: 150px;
}

.navLogo img {
  height: 75px;
  width: 75px;
  object-fit: cover;
  //margin-left: 20px;
  justify-self: center;
  align-items: center;
}

.navLogo h2 {
  color: white;
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  justify-self: center;
  align-self: center;
  top: 35px;
  line-height: 1;
}

.navLogo a {
  font-size: 16px;
  color: grey;
  font-weight: 200;
  text-decoration: none;
}

.genNavBarbtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
}

.genNavBarbtn button{
  border: none;
  padding: 2px 15px;
  //padding-top: 4px;
  font-size: 15px;
  margin: 0;
  border-radius: 22px;
  background-color: #ffc700;
  margin-right: 18px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid black;
  color: #151515;
  font-weight: 700;
}

.yellowCircle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #ffc700;
  box-shadow: 0px 0px 3px 3px #ffc700;
  transition: .2s;
  display: none;
} 

.yellowCircle.green {
  background: limegreen;
  box-shadow: 0px 0px 3px 3px lime;
}

.genContainer h1 {
  color: white;
  font-size: 180px;
  padding: 0;
  margin: 0;
  line-height: 1;
  //margin-top: 200px;  
  //margin-bottom: 200px;
  position: relative;
  display: none;
}

.coinContainer {
  //display: grid;
  //position: absolute;
  //height: 100%;
  //width: 100%;
  //top: -140px;
  //left: 0;
  //margin-bottom: 500px;
}

.gencontainerbtn {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40%;
  display: none;
}

.gencontainerbtn button {
  margin-bottom: 0 !important;
  margin-left: 40px !important;
  margin-right: 0px;
  padding: 2px 15px !important;
}
.yellowCircle {
  margin-left: 20px;
}
#coin {
  position: relative;
  justify-self: center;
  align-self: center;
  height: 500px;
  width: 500px;
  
}

.inputField {
  height: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //margin-bottom: 125px;
}

.inputField input {
  height: 100%;
  width: 80%;
  border-radius: 25px 0px 0px 25px;
  border: none;
  padding-left: 20px;
  font-size: 20px;
  margin-bottom: 0;
}

.inputField input:focus {
  border: none;
  outline: none;
}

.inputField {
  opacity: 0;
  //margin-bottom: 100%;
  transition: .5s;
}

.inputField.active {
  margin-bottom: 5%;
  opacity: 1;
  height: 40px;
}
.gentitleCon {
  position: absolute;
  height: auto;
  width: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.genContainer button {
  border: none;
  padding: 6px 20px;
  padding-top: 8px;
  font-size: 17px;
  margin: 0;
  border-radius: 25px;
  background-color: white;
  color: #151515;
  font-weight: 600;
  cursor: pointer;
}


.inputField button {
  height: 42px;
  width: 20%;
  border-radius: 0px 25px 25px 0px;
  //border: 1px solid white;
  padding: 6px;
  font-size: 22.5px;
  border: 3px solid white;
  background: rgb(240, 240, 240);
  color: white;
  margin-bottom: 0;
  background: black;
}

.tweet {
  display: none;
  background: #1DA1F2 !important;
  color: white !important;
  font-weight: 500;
  text-decoration: none;
  padding: 4 20px;
  padding-top: 6px;
  font-size: 20px;
}
a {
  text-decoration: none;
}


.tweet.active {
  display: block;

}

.noPW {
  display: none;
}

.noPW.active {
  display: block;
}

.Wallet-Connect {
  display: none;
  //background: white;
  margin-bottom: 100%;
  transition: .2s;
}

.Wallet-Connect button {
  background: white;
}

.Wallet-Connect.active {
  display: none;
  margin-bottom: 0;
}

.pwbtn {
  display: none;
}

.pwbtn.active {
  display: none;
}

#firstP {
  font-size: 20px;
  font-style: italic;
  margin-bottom: 20px;
}

.genContainer p {
  color: white;
  padding: 0;
  margin: 0;
  font-size: 18px;
  width: 80%;
  margin-top: 10px;
  font-weight: 400;
}

.links {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin-top: 25px;
  margin-bottom: 35px;
}

.links a {
  margin-top: 10px;
  color: #c7a700;
  text-decoration: none;
  font-weight: 500;
  font-size: 17px;
}

.p {
  opacity: 0;
  transition: .5s;
}

.p.active {
  opacity: 1;
}


// loading content 

.loading {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
#loadingBGIMG {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.loadingContent {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.loadingContent img {
}

.loadingBarCon {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#skullCoin {
  height: 75px;
  width: 75px;
  object-fit: cover;
}

.loadingBarCon p {
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
  color: white;
  letter-spacing: 0px;
  font-size: 17.5px;
  font-weight: 700;
  width: 100%;
  text-align: center;
}

#progPercentage {
  color: white;
  letter-spacing: 0px;
  font-size: 20px;
  font-weight: 700;

}

.loadingBar {
  margin-top: 0%;
  padding: 1px;
  height: 4px;
  width: 100%;
  background: transparent;
  border: 1px solid white;
  border-radius: 15px;
  overflow: hidden;
}

.progressBarCon {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}

.progressBar {
  background: white;
  height: 100%;
  width: 0%;
  animation-name: loadingProgress;
  animation-delay: .5s;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}

@keyframes loadingProgress {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@media (max-width: 1650px) {
  .loadingContent h2 {
    margin-bottom: 0%;
  }
  .loadingBarCon {
    margin-bottom: 30%;
    width: 25%;
  }
}

@media (max-width: 950px) {
  .loadingContent img {
    height: 275px;
  }

  .loadingBarCon {
    width: 80%;
    margin-left: 0%;
  }

  .loadingBarCon p {
    font-size: 20px;
  }

  
}

.loadingEnterFade {
  height: 100%;
  width: 100%;
  position: absolute;
  background: black;
  top: 0;
  left: 0;
  animation-name: loadingFadeIn;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  z-index: 11;
}

@keyframes loadingFadeIn {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
}

.loadingExitFade {
  height: 100%;
  width: 100%;
  position: absolute;
  background: black;
  top: 0;
  left: 0;
  opacity: 0;
  animation-name: loadingFadeOut;
  animation-delay: 2.25s;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  z-index: 999;

}

@keyframes loadingFadeOut {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 950px) {

  #loadingBGIMG {
    object-position: -600px;
  }

  .loadingBarCon {
    width: 45%;
  }
  #mainBG {
    top: 0;
    border-right: none;
    border-bottom: 2px solid white;
    //object-position: -420px;
    height: 100vh;
    width: 100%;
    object-position: -600px;
    //width: 102%;
    //margin-right:400%;
  }

  .genNavBarbtn button {
    padding: 8px;
  }


  #mainBG.active {
    left: 0;
    top: -100.25%;
  }

  .landing {

  }

  #landing {
    left: 0;
    top: 0;
  }

  #landing.active {
    left: 0;
    top: -100%;
  }

  .titleCon {
    margin-left: 0%;
    width: 100%;
  }
  .titleCon h1 {
    font-size: 80px;
  }

  .titleCon button {
    font-size: 18px;
    font-weight: 600;
    margin-top: 0px;
    padding: 6px 20px;
  }

  .titleCon p {
    font-size: 10px;
    width: 80%;
    margin-top: 10px;
  }
  .genNavBar {
    justify-content: center;
    display: none;
  }
  .navLogo {
    display: none;
    height: 50px;
    margin-top: 15px;
  }

  .navLogo img {
    display: none;
  }
  .navLogo h2 {
    top: 0;
    font-size: 12px;
  }

  .navLogo a {
    font-size: 10px;
  }
  
  .genNavBarbtn {
    margin-left: 12.5%;
  }

  .genNavBar button {
    font-size: 12.5px;
    margin-right: 25px;
    padding: 2px 15px;
    padding-top: 3px;
    font-weight: 600;
  }
  #genContainer {
    left: 0%;
    top: 0%;
  }
  #genContainer.active {
    left: 0%;
    top: 0%;
  }

  .genContainer h1 {
    font-size: 60px;
    //margin-top: 180px;
    margin-bottom: 30px;
    display: block;
  }

  .h1.active {
    display: none;
  } 

  .coinContainer {
    margin-bottom: 10%;
  }

  #coin {
    height: 500px;
    width: 500px;
    top: 80px;
  }

  #firstP {
    font-size: 17px;
    margin-bottom: 5px;
  }

  .genContainer input {
    width: 80%;
  }
  .genContainer button {
   // margin-bottom: 225px;
  }

  .genContainer p {
    width: 90%;
    font-size: 9px;
    line-height: 1.4;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 300;
    display: none;
  }


  .links {
    width: 80%;
    margin-bottom: 15px;
  }
  .links a {
    font-size: 8px;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  #lastP {
    display: none;
    font-size: 8px;
    width: 80%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .inputField {
    width: 100%;
    //margin-bottom: 100%;
  }
  .gentitleCon {
    width: 80%;
  }
  .inputField.active {
    //margin-bottom: 70%;
  }
  
  .inputField button {
    height: 55px;
    width: 25%;
    font-size: 15px;
    margin-bottom: 0;
  }
  .gencontainerbtn {
    width: 80%;
    margin-bottom: 0%;
    display: none;
  }
  .gencontainerbtn.active {
    display: flex;
  }
  .genNavBar.active {
    display: none;
  }
  .tweet {
    margin-bottom: 30px !important;
  }
}